import React, { createContext, useContext, useEffect, useState } from "react";

const SlideoutContext = createContext();

const SlideoutProvider = ({ children }) => {
  const [ activeSidbar, setActiveSidebar ] = useState(false);
  const [ userDataObj, setUserDataObj ] = useState(null);
  const [ object, setObject ] = useState({});
  const [ handleChange, setHandleChange ] = useState(null);
  const [ yearData, setYearData ] = useState(null);
  const [ year, setYear ] = useState(new Date().getFullYear());
  const [ view, setView ] = useState(null);
  const [ slideoutType, setSlideoutType ] = useState({});

  return (
    <SlideoutContext.Provider
      value={{
        activeSidbar,
        setActiveSidebar,
        object,
        setObject,
        handleChange,
        setHandleChange,
        year,
        setYear,
        view,
        setView,
        slideoutType,
        setSlideoutType,
        yearData,
        setYearData,
        userDataObj,
        setUserDataObj
      }}
    >
      {children}
    </SlideoutContext.Provider>
  );
};

export const SliderState = () => {
  return useContext(SlideoutContext);
};

export default SlideoutProvider;