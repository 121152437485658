import React, { useState, useEffect } from 'react'
import {Link} from 'react-router-dom'
import {Navbar, Nav, NavDropdown, Dropdown } from 'react-bootstrap'

function Header({notificationNum, userDataObj}) {
    const [ isLoggedIn , setIsLoggedIn ] = useState(null);

    function logout() {
        fetch('/logout')
            .then(function(res) {
                sessionStorage.clear();
                localStorage.removeItem('currentYear');
                window.location.href = "/";
            })
            .catch(function(err) {
                console.log(err);
            });
    }

    function clickDropdown() {
        document.querySelector('#basic-nav-dropdown').click();
    }

    const year = new Date().getFullYear();
    const path = window.location.pathname;

    useEffect(() => {
        setIsLoggedIn(()=>{
            return /secure_user/.test(document.cookie) ? true : false;
        })
    },[]);

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <span ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }} >
            {children}
        </span>
    ));

    const notifyNum = notificationNum > 0 ? notificationNum : "";
    
    return (
        <>
        <Navbar bg="dark" expand="lg" sticky={'top'} className="navbar-dark">
            <Navbar.Brand><Link to="/life/overview"><img className="headerlogo" src="/images/brightyear-visual-logo-gradient.png" alt="Brightyear.io Header Logo" /></Link></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            {!!isLoggedIn && (()=>{
                return (
                    <>
                    <div onClick={(e)=>{e.currentTarget.classList.add('noDisplay')}} className="dropdown-bg noDisplay"></div>
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav className="navbar-right">
                            <div className="main-head-nav">
                                <Link className={path === '/life/overview' ? 'active' : ""} to="/life/overview"><span className="headerIcon"><i className="bi-bar-chart"></i></span>Overview</Link>
                                <Link className={/\/life\/goals/.test(path) ? 'active' : ""} to={"/life/goals/"+year}><span className="headerIcon"><i className="bi-check-circle"></i></span>Goals</Link>
                                <Link className={/\/life\/dreams/.test(path) ? 'active' : ""} to="/life/dreams"><span className="headerIcon"><i className="bi-trophy"></i></span>Dreams</Link>
                                {/* <Link className={/\/life\/habits/.test(path) ? 'active' : ""} to={"/life/habits/"+year}><span className="headerIcon"><i className="bi-arrow-counterclockwise"></i></span>Habits</Link> */}
                                <Link className={/\/life\/feed/.test(path) ? 'active' : ""} to="/life/feed"><span className="headerIcon"><i className="bi-card-heading"></i></span>Feed</Link>
                                <Link className={/\/life\/learning/.test(path) ? 'active' : ""} to="/life/learning"><span className="headerIcon"><i className="bi-lightbulb"></i></span>Learn</Link>
                                <Link className={/\/life\/notes/.test(path) ? 'active' : ""} to="/life/notes"><span className="headerIcon"><i className="bi-pencil-square"></i></span>Notes</Link>
                                {/* <Link className={/\/life\/analyzer/.test(path) ? 'active' : ""} to="/life/analyzer"><span className="headerIcon"><i className="bi-graph-up"></i></span>Analyzer</Link> */}
                                <Link className="search-icon" to="/people/search"><i className="bi-search pointer"></i></Link>
                                <Link className="notify-icon" to="/life/notifications"><i className="bi-bell-fill"></i><span className="notify-num">{notifyNum}</span></Link>
                                <i onClick={clickDropdown} className="bi-file-person-fill"></i>
                            </div>
                            <NavDropdown title="" id="basic-nav-dropdown">
                                {!!userDataObj && userDataObj.subType === "free" && (()=>{
                                    return (
                                        <>
                                        <Link to={`/subscribe/premium`}><span className="dropdown-item"><span className="link">Go premium</span></span></Link>
                                        <NavDropdown.Divider />
                                        </>
                                    )
                                })()}
                                {!!userDataObj && (()=>{
                                    return <Link className={path.indexOf(userDataObj.shareLink) > -1 ? 'active' : ""} to={`/people/${userDataObj.shareLink}`}><span className="dropdown-item">Profile</span></Link>
                                })()}
                                <NavDropdown.Divider />
                                <Link to={"/life/habits/"+year}><span className="dropdown-item">Habits</span></Link>
                                <NavDropdown.Divider />
                                <Link to="/life/connections"><span className="dropdown-item">Connections</span></Link>
                                <NavDropdown.Divider />
                                <Link to="/life/awards"><span className="dropdown-item">Awards</span></Link>
                                <NavDropdown.Divider />
                                <Link to="/life/settings"><span className="dropdown-item">Settings</span></Link>
                                <NavDropdown.Divider />
                                <span className="dropdown-item" onClick={logout}>Log out</span>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                    <div id="mobile-bottom-nav">
                        <ul>
                            <li><Link className={path === '/life/overview' ? 'active' : ""} to="/life/overview"><span><span className="headerIcon"></span><i className="bi-bar-chart"></i></span><span>Overview</span></Link></li>
                            <li><Link className={/\/life\/goals/.test(path) ? 'active' : ""} to={"/life/goals/"+year}><span><span className="headerIcon"></span><i className="bi-check-circle"></i></span><span>Goals</span></Link></li>
                            <li><Link className={/\/life\/dreams/.test(path) ? 'active' : ""} to="/life/dreams"><span><span className="headerIcon"></span><i className="bi-trophy"></i></span><span>Dreams</span></Link></li>
                            <li><Link className={/\/life\/notifications/.test(path) ? 'active' : ""} to="/life/notifications"><span><span className="headerIcon"></span><i className="bi-bell-fill"></i></span><span>Alerts</span><span className="notify-num">{notifyNum}</span></Link></li>
                            <li>
                                <Dropdown className="dropdown-button-drop-up options-dropdown" drop={'up'} variant="secondary" >
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                    <span><span className="headerIcon"></span><i className="bi-three-dots"></i></span><span>More</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href={"/life/habits/"+year} eventKey="1"><i className="bi-arrow-counterclockwise"></i>Habits</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item href="/life/feed" eventKey="2"><i className="bi-card-heading"></i>Feed</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item href="/life/learning" eventKey="3"><i className="bi-trophy"></i>Learn</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item href="/life/notes" eventKey="4"><i className="bi-pencil-square"></i>Notes</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item href="/life/connections" eventKey="5"><i className="bi-people"></i>Connections</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item href="/people/search" eventKey="6"><i className="bi-search"></i>Search</Dropdown.Item>
                                        <Dropdown.Divider />
                                        {/* <Dropdown.Item href="/life/analyzer" eventKey="3"><i className="bi-graph-up"></i>Analyzer</Dropdown.Item>
                                        <Dropdown.Divider /> */}
                                        {!!userDataObj && userDataObj.subType === "free" && (()=>{
                                            return (
                                                <>
                                                <Dropdown.Item href="/subscribe/premium" eventKey="4"><i className="bi-award"></i><span className="link">Go premium</span></Dropdown.Item>
                                                <Dropdown.Divider />
                                                </>
                                            )
                                        })()}
                                        {!!userDataObj && Object.keys(userDataObj).length > 1 && (()=>{
                                            return (
                                                <>
                                                    <Dropdown.Item href={`/people/${userDataObj.shareLink}`} eventKey="5"><i className="bi-person"></i>Profile</Dropdown.Item>
                                                    <Dropdown.Divider />
                                                </>
                                            )
                                        })()}
                                        <Dropdown.Item href="/life/awards" eventKey="6"><i className="bi-award"></i>Awards</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item href="/life/settings" eventKey="7"><i className="bi-gear"></i>Settings</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                        </ul>
                    </div>
                    </>
                )
            })()}
            {!isLoggedIn && (()=>{
                return (
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav className="navbar-right">
                            <Link to="/login">Log in</Link>
                            {/* <Link to="/signup" className="nav-cta btn" href="#action-section">Sign up</Link> */}
                        </Nav>
                    </Navbar.Collapse>
                )
            })()}
        </Navbar>
        </>
    )
}

export default Header