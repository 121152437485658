import React, { useEffect, useState, Suspense, lazy } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import './App.css';
import Header from './components/navigation/Header'
import axios from 'axios'

const Login = lazy(() => import('./pages/Login'));
const Signup = lazy(() => import('./pages/Signup'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const PasswordReset = lazy(() => import('./pages/PasswordReset'));
const People = lazy(() => import('./pages/People'));
const Subscribe = lazy(() => import('./pages/Subscribe'));
const Goals = lazy(() => import('./pages/Goals'));
const AllGoalsPage = lazy(() => import('./pages/AllGoalsPage'));
const Overview = lazy(() => import('./pages/Overview'));
const Dreams = lazy(() => import('./pages/Dreams'));
const Feed = lazy(() => import('./pages/Feed'));
const Notifications = lazy(() => import('./pages/Notifications'));
const Awards = lazy(() => import('./pages/Awards'));
const Connections = lazy(() => import('./pages/Connections'));
const Settings = lazy(() => import('./pages/Settings'));
const Error404 = lazy(() => import('./pages/Error404'));
const Notes = lazy(() => import('./pages/Notes'));
const Learning = lazy(() => import('./pages/Learning'));
const Unsubscribe = lazy(() => import('./pages/Unsubscribe'));
const Onboarding = lazy(() => import('./pages/Onboarding'));
const Habits = lazy(() => import('./pages/Habits'));
// const Analyzer = lazy(() => import('./pages/Analyzer'));

function App() {
    const [ isOnline, setIsOnline ] = useState(true);
    const [ notificationNum , setNotificationNum ] = useState(0);
    const [ userDataObj, setUserDataObj ] = useState(null);
    const [ appType, setAppType ] = useState(null);
    const ENV = /localhost:3000/i.test(window.location.origin) ? "http://localhost:4000" : "";
    const userLoggedIn = /secure_user/.test(document.cookie);
    const location = useLocation();

    useEffect(() => {
        const handleControllerChange = () => {
            navigator.serviceWorker.ready.then(async (registration) => {
                if (registration?.waiting?.state === "installed") {
                    let text = "New version of the app is ready to be installed. Select OK to install and refresh.";
                    if (window.confirm(text) == true) {
                        // registration.waiting.postMessage({type: 'SKIP_WAITING'})
                        navigator.serviceWorker.controller.postMessage({ type: 'SKIP_WAITING' });
                        setTimeout(()=>{
                            window.location.reload();
                        },3000)
                    }
                }
            });
        };

        navigator.serviceWorker.addEventListener('controllerchange', handleControllerChange);

        return () => {
            navigator.serviceWorker.removeEventListener('controllerchange', handleControllerChange);
        };
    }, []);

    // Differentiate from TWA and PWA
    if (!userLoggedIn && (!localStorage.initAppType || (!!localStorage.initAppType && localStorage.initAppType === "PWA")) ) {
        if (/appType=TWA/i.test(window.location.href)) {
            localStorage.setItem('initAppType',"TWA");
        } else {
            localStorage.setItem('initAppType',"PWA");
        }
    } else if (!appType && !!userLoggedIn) {
        // http://localhost:4000/?appType=TWA
        const pattern = /^[^\)]*/, ua = pattern.exec(navigator.userAgent)[0]+")";
        if ((!!localStorage.initAppType && localStorage.initAppType === "TWA") || /appType=TWA/i.test(window.location.href)) {
            setAppType("TWA");
            if (!localStorage.appType || (!!localStorage.appType && localStorage.appType === "PWA")) {
                // set localStorage.appType 
                localStorage.setItem('appType',"TWA");
                localStorage.setItem('initAppType',"TWA");
         
                // Make a POST call pushing navigator.userAgent string to database
                axios.post(`${ENV}/app-type`,{
                    ua,
                    type: "TWA"
                }).then((res)=>{
                    if (!!res && !!res.data && !!res.data.successMessage) {
                        console.log('success',res.data.successMessage)
                    }
                }).catch(err=>{
                    setAppType("TWA");
                    localStorage.setItem('appType',"TWA");
                    localStorage.setItem('initAppType',"TWA");
                });
            }
         } else {
            // If appType on start_url doesn't exist
            if (!localStorage.appType) {
               // Make a GET call to check if userAgent is stored in database
               axios(`${ENV}/app-type?ua=${encodeURIComponent(ua)}`)
                .then((res)=>{
                    if (!!res && !!res.data && !!res.data.appType) {
                        setAppType("TWA");
                        localStorage.setItem('appType',"TWA");
                        localStorage.setItem('initAppType',"TWA");
                    } else {
                        setAppType("PWA");
                        localStorage.setItem('appType',"PWA");
                        localStorage.setItem('initAppType',"PWA");
                    }
                }).catch(err=>{
                    setAppType("TWA");
                    localStorage.setItem('appType',"TWA");
                    localStorage.setItem('initAppType',"TWA");
                });
            } else {
               setAppType(localStorage.appType);
            }
        }
    }

    if (!userLoggedIn && window.location.pathname !== "/" && !/signup|login|onboarding|forgot-password|unsubscribe|password-reset|error-404/.test(window.location.pathname)) {
        if (window.location.origin === 'https://my.brightyear.io' || window.location.origin === 'http://localhost:3000') {
            window.location.href = `${window.location.origin}/login?returnUrl=${encodeURIComponent(window.location.href)}`;
        }
    }

    useEffect(()=>{
        function isOnline() {
            console.log('came online')
            setIsOnline(true)
        }
        function isOffline() {
            console.log('came offline')
            setIsOnline(false)
        }
        window.addEventListener('online',isOnline);
        window.addEventListener('offline',isOffline);
        return () => {
            window.removeEventListener('online',isOnline);
            window.removeEventListener('offline',isOffline);
        };
    },[]);

    // Make sure to not pass people pages to GA
    useEffect(()=>{
        if (/people/i.test(location.pathname)) {
            window['ga-disable-G-SEW6TB914K'] = true;
        } else if (typeof window['ga-disable-G-SEW6TB914K'] !== "undefined") {
            delete window['ga-disable-G-SEW6TB914K'];
        }
    },[location.pathname]);

    useEffect(()=>{
        if (userLoggedIn) {
            axios(`${ENV}/notification-num`)
            .then(res=>{
                if (res.data.successMessage) {
                    setNotificationNum(res.data.num)
                }
                if (res.data.errorMessage) {
                    console.log(res.data.errorMessage);
                }
            })
            .catch(err=>{
                console.log(err)
            })    
        }
    },[]);

    useEffect(()=>{
        if (userLoggedIn) {
            try {
                axios(`${ENV}/user-data`)
                .then(res=>{
                    if (res.data.successMessage) {
                        setUserDataObj(res.data.data);
                        document.cookie = `st=${/premium/i.test(res.data.data.subType)?"p":"f"};path=/;domain=brightyear.io`;
                    }
                    if (res.data.errorMessage) {
                        console.log(res.data.errorMessage);
                    } 
                })
                .catch(err=>{
                    console.log(err)
                })
            } catch(err) {
                console.log("error")
            }
        }
    },[]);

    const loaderSection = (
        <>
        <Header ENV={ENV} notificationNum={notificationNum} userDataObj={userDataObj} />
        <div id="loader-cont">
            <div className="loader"></div>
            <img className="loader-logo" src="/images/brightyear-visual-logo-gradient.png" alt="Website Header Logo" />
        </div>
        </>
    );
    return (
        <div className="app-body-container">
            {!!isOnline ? (()=>{
                return (
                    <Suspense fallback={loaderSection}>
                        <Switch>
                            <Route exact path="/life/overview">
                                {userLoggedIn ? (
                                    <>
                                    <Header ENV={ENV} notificationNum={notificationNum} userDataObj={userDataObj} />
                                    <Overview ENV={ENV} userDataObj={userDataObj} />
                                    </>
                                ) : (
                                    <Redirect to="/login" />
                                )}
                            </Route>
                            <Route exact path="/life/goals/:type/:year">
                                {userLoggedIn ? (
                                    <>
                                        <Header ENV={ENV} notificationNum={notificationNum} userDataObj={userDataObj} />
                                        <AllGoalsPage ENV={ENV} userDataObj={userDataObj} />
                                    </>
                                ) : (
                                    <Redirect to="/login" />
                                )}
                            </Route>
                            <Route exact path="/life/goals/:year">
                                {userLoggedIn ? (
                                    <>
                                        <Header ENV={ENV} notificationNum={notificationNum} userDataObj={userDataObj} />
                                        <Goals ENV={ENV} userDataObj={userDataObj} />
                                    </>
                                ) : (
                                    <Redirect to="/login" />
                                )}
                            </Route>
                            <Route path="/life/dreams*">
                                {userLoggedIn ? (
                                    <>
                                        <Header ENV={ENV} notificationNum={notificationNum} userDataObj={userDataObj} />
                                        <Dreams ENV={ENV} userDataObj={userDataObj} />
                                    </>
                                ) : (
                                    <Redirect to="/login" />
                                )}
                            </Route>
                            <Route path="/life/feed*">
                                {userLoggedIn ? (
                                    <>
                                        <Header ENV={ENV} notificationNum={notificationNum} userDataObj={userDataObj} />
                                        <Feed ENV={ENV} userDataObj={userDataObj} />
                                    </>
                                ) : (
                                    <Redirect to="/login" />
                                )}
                            </Route>
                            <Route path="/life/notifications">
                                {userLoggedIn ? (
                                    <>
                                        <Header ENV={ENV} notificationNum={notificationNum} userDataObj={userDataObj} />
                                        <Notifications ENV={ENV} setNotificationNum={setNotificationNum} userDataObj={userDataObj} />
                                    </>
                                ) : (
                                    <Redirect to="/login" />
                                )}
                            </Route>
                            <Route path="/life/awards">
                                {userLoggedIn ? (
                                    <>
                                        <Header ENV={ENV} notificationNum={notificationNum} userDataObj={userDataObj} />
                                        <Awards ENV={ENV} />
                                    </>
                                ) : (
                                    <Redirect to="/login" />
                                )}
                            </Route>
                            <Route path="/people*">
                                {userLoggedIn ? (
                                    <>
                                        <Header ENV={ENV} notificationNum={notificationNum} userDataObj={userDataObj} />
                                        <People ENV={ENV} userDataObj={userDataObj} />
                                    </>
                                ) : (
                                    <Redirect to="/login" />
                                )}
                            </Route>
                            <Route path="/subscribe*">
                                {userLoggedIn ? (
                                    <>
                                        <Header ENV={ENV} notificationNum={notificationNum} userDataObj={userDataObj} />
                                        <Subscribe ENV={ENV} userDataObj={userDataObj} appType={appType} />
                                    </>
                                ) : (
                                    <Redirect to="/login" />
                                )}
                            </Route>
                            <Route path="/life/connections*">
                                {userLoggedIn ? (
                                    <>
                                        <Header ENV={ENV} notificationNum={notificationNum} userDataObj={userDataObj} />
                                        <Connections ENV={ENV} userDataObj={userDataObj} />
                                    </>
                                ) : (
                                    <Redirect to="/login" />
                                )}
                            </Route>
                            <Route exact path="/life/notes*">
                                {userLoggedIn ? (
                                    <>
                                        <Header ENV={ENV} notificationNum={notificationNum} userDataObj={userDataObj} />
                                        <Notes ENV={ENV} userDataObj={userDataObj} />
                                    </>
                                ) : (
                                    <Redirect to="/login" />
                                )}
                            </Route>
                            <Route exact path="/life/learning*">
                                {userLoggedIn ? (
                                    <>
                                        <Header ENV={ENV} notificationNum={notificationNum} userDataObj={userDataObj} />
                                        <Learning ENV={ENV} userDataObj={userDataObj} />
                                    </>
                                ) : (
                                    <Redirect to="/login" />
                                )}
                            </Route>
                            <Route path="/life/habits/:year">
                                {userLoggedIn ? (
                                    <>
                                        <Header ENV={ENV} notificationNum={notificationNum} userDataObj={userDataObj} />
                                        <Habits ENV={ENV} userDataObj={userDataObj} />
                                    </>
                                ) : (
                                    <Redirect to="/login" />
                                )}
                            </Route>
                            {/* <Route exact path="/life/analyzer">
                                {userLoggedIn ? (
                                    <>
                                    <Header ENV={ENV} notificationNum={notificationNum} userDataObj={userDataObj} />
                                    <Analyzer ENV={ENV} userDataObj={userDataObj} />
                                    </>
                                ) : (
                                    <Redirect to="/login" />
                                )}
                            </Route> */}
                            <Route exact path="/life/settings">
                                {userLoggedIn ? (
                                    <>
                                        <Header ENV={ENV} notificationNum={notificationNum} userDataObj={userDataObj} />
                                        <Settings ENV={ENV} userDataObj={userDataObj} appType={appType} />
                                    </>
                                ) : (
                                    <Redirect to="/login" />
                                )}
                            </Route>
                            <Route exact path="/onboarding*">
                                <Onboarding ENV={ENV} userDataObj={userDataObj} appType={appType} userLoggedIn={userLoggedIn} />
                            </Route>
                            <Route exact path="/signup">
                                {userLoggedIn ? (
                                    <Redirect to="/life/overview" />
                                ) : (
                                    <Signup ENV={ENV} />
                                )}
                            </Route>
                            <Route exact path="/login">
                                {userLoggedIn ? (
                                    <Redirect to="/life/overview" />
                                ) : (
                                    <Login ENV={ENV} />
                                )}
                            </Route>
                            <Route exact path="/forgot-password">
                                <ForgotPassword ENV={ENV} />
                            </Route>
                            <Route exact path="/unsubscribe/:type/:user_id">
                                <Unsubscribe ENV={ENV} />
                            </Route>
                            <Route exact path="/password-reset/:token/:user_id">
                                <PasswordReset ENV={ENV} />
                            </Route>
                            <Route exact path="/">
                                <Redirect to="/life/overview" />
                            </Route>
                            <Route exact path="/error-404">
                                <Header ENV={ENV} notificationNum={notificationNum} userDataObj={userDataObj} />
                                <Error404 />
                            </Route>
                            <Route path="/*">
                                <Redirect to="/error-404" />
                            </Route>
                        </Switch>
                    </Suspense>
                )
            })() : (()=>{
                return (
                    <>
                    <Header ENV={ENV} notificationNum={notificationNum} userDataObj={userDataObj} />
                    <div className="text-center" style={{display: "block", margin: "30vh auto 40px auto", maxWidth: "175px"}}>
                        <i className="bi-wifi-off size10"></i>
                        <div>You are offline</div>
                    </div>
                    </>
                )
            })()}
        </div>
    );
}

export default App;